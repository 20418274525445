<template>
  <div v-loading.fullscreen.lock="loading">
    <el-dialog
      title="提示"
      :visible.sync="uploadShow"
      width="30%"
      :before-close="handleClose"
    >
      <div class="flex align-items justify-content-center marginBottom20">
        <el-upload
          class="upload-demo"
          :drag="true"
          :action="action"
          :show-file-list="false"
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="text" size="medium" @click="handleDownload"
          >下载文件模板</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleOne"
      width="30%"
      :before-close="handleCloseOne"
    >
      <div
        v-if="problemState === 1"
        style="text-align: center; margin-bottom: 20px"
      >
        <p>导入成功</p>
      </div>
      <div v-if="problemState === 2" style="text-align: center">
        <p>部分数据已导入成功</p>
        <p>问题数据请通过下载获取</p>
      </div>
      <div v-if="problemState === 3" style="text-align: center">
        <p>全部导入失败</p>
      </div>
      <div
        v-if="problemState === 4"
        style="text-align: center; margin-bottom: 20px"
      >
        <p>数据为空导入失败</p>
      </div>
      <div
        v-if="problemState === 3 || problemState === 2"
        class="flex align-items justify-content-center marginBottom10"
      >
        <el-button type="text" size="medium" @click="handleDownloadProblem"
          >下载问题数据</el-button
        >
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseOne"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { exportExcel } from "@/components/exportExcel";
export default {
  name: "index",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "",
    },
    downloadLink: {
      type: String,
      default: "",
    },
    problemLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      uploadShow: this.show,
      dialogVisibleOne: false,
      problemState: 0,
      problemId: "",
    };
  },
  methods: {
    // 下载问题数据
    async handleDownloadProblem() {
      console.log("123123");
      await exportExcel(
        this.problemLink,
        { id: this.problemId },
        this.$store.state.user.Token
      );
    },
    // 关闭弹框
    handleClose() {
      setTimeout(() => {
        this.dialogVisibleOne = true;
      }, 200);
      this.$emit("close", this.problemState);
    },
    handleCloseOne() {
      this.$emit("close", this.problemState);
    },
    // 导入成功
    handleSuccess(res, file) {
      // // console.log(1111111,res, file);
      this.uploadShow = false;
      if (res.code === 200) {
        this.problemState = res.data.state;
        setTimeout(() => {
          this.problemId = res.data.id;
          this.dialogVisibleOne = true;
        }, 500);
      } else {
        this.uploadShow = false;
      }
    },
    beforeUpload(file) {
      this.loading = true;
      // 文件类型
      const isType = file.type === "application/vnd.ms-excel";
      const isTypeComputer =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是xls/xlsx格式！");
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过20M！");
      }
      // this.loading = false
      return fileType && fileLimit;
    },
    // 下载文件模板
    async handleDownload() {
      await exportExcel(this.downloadLink, "", this.$store.state.user.Token);
    },
  },
};
</script>

<style scoped></style>
