<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >问题标题 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.title"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                style="
                  background: #f59a23;
                  color: #ffffff;
                  border-color: #f59a23;
                "
                @click="handleImport"
                >导入</el-button
              >
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                @click="handleCreate('', 'add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="isHome" slot-scope="{ row }">
        <el-switch
          :value="row.isHome"
          :inactive-value="2"
          :active-value="1"
          @change="handleSwitch($event, row.id)"
        ></el-switch>
      </template>
      <template slot="image" slot-scope="{ row }">
        <el-image
          style="width: 80px; height: 80px"
          :src="row.image"
          :preview-src-list="[row.image]"
        >
        </el-image>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <!-- <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
        <el-button type="text" size="mini" @click="handleCreate(row, 'edit')"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import {
  qaManageList,
  qayManageDelete,
  qaManageHomeUpdate,
} from "@/api/qa/manage";
import { mapGetters } from "vuex";
import Import from "@/components/import";

export default {
  name: "index",
  components: {
    Import,
  },
  data() {
    return {
      classifyList: [],
      searchData: {
        title: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "问题标题",
            prop: "title",
          },
          {
            label: "问答内容",
            prop: "content",
          },
          {
            label: "查看次数",
            prop: "num",
          },
          {
            label: "首页推荐",
            prop: "isHome",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },
      dialogVisible: false,
      importShow: false, //导入弹窗
      importAction: "/api/web/qa/manage/template/import",
      importProblem: "/web/qa/manage/template/error/export",
      importDownloadLink: "/web/qa/manage/template/export",
    };
  },
  created() {},
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        ...this.searchData,
      };
      if (this.searchData.classifyId && this.searchData.classifyId.length) {
        searchForm.classifyId = this.searchData.classifyId.join(",");
      }
      this.showLoading = true;
      qaManageList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchForm,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 新增编辑
    handleCreate(row, type) {
      this.$router.push({
        path: "/qa/manage/create",
        query: {
          id: row.id,
          type: type,
        },
      });
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        qayManageDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        title: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },

    // 批量导入
    handleImport() {
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      console.log("e111", e);
      this.onLoad();
    },
    //切换是否显示
    handleSwitch(e, id) {
      qaManageHomeUpdate({
        id: id,
        isHome: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
